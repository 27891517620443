import  React from 'react';
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay, EffectFade, Navigation, Thumbs, Pagination} from 'swiper';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/Image';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Thumbs, EffectFade, Autoplay, Pagination]);

const GallerySlide = (props) => {
  const data = props;
  const sliderList = [];
  for (let i = 0; i < data.imgLength; i++) {
    sliderList.push(
        <SwiperSlide key={`${i}`}>
          <Image
              className="w-full"
              filename={`chimney/slide-${i + 1}.jpg`}
          />
        </SwiperSlide>
    );
  }
  return (
      <>
        <Swiper
            className="page-main-slider"
            loop
            speed={1000}
            navigation
            slidesPerView={1.6}
            centeredSlides
        >
          {sliderList}
        </Swiper>
      </>
  );
};

const ChimneyPage = () => (
    <Layout page="chimney-page">
      <Seo title="CHIMNEY"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="md:pl-24">
          <Image filename="logo/chimney.png" alt="Shingu Chimney System 匠" />
        </h2>
      </section>
      <section className="page-content full-layout">
        <div className="main-content">
          <div className="mv-area">
            <GallerySlide
                case={1}
                imgLength={2}
            />
            <div className="chimney-image-wrap">
              <div className="content-block">
                <p className="md:text-3xl text-lg c-yellow md:pt-20 pt-12">
                  <span className="leading-loose tracking-widest">美しさと高性能を<br className="pc" />
                  兼ね備えたニッポン品質<br className="pc" />
                  薪ストーブライフを<br className="pc" />
                  さらに美しく快適にします。</span>
                </p>
              </div>
              <div className="black-back">
                <Image filename="chimney/chimney-2.jpg" />
                <p className="text-white mt-10">薪ストーブの性能を最大限に発揮させるものとして、使用する燃料である薪の樹種や含水量などの正しい選択の他に、薪ストーブの燃焼室内で発生した煙を速やかに屋外へ排出させるための煙突が必要となります。</p>
                <p className="text-white mt-6">新宮商行の二重断熱煙突SCS匠はこれらの機能はもちろん兼ね備えておりその上でこれまでにない見た目の美しさを追求しました。</p>
                <a className="btn pdf black full mt-6" href="/update/pdf/chimney-pamphlet.pdf" target="_blank">
                  SCS匠 パンフレット ダウンロード
                </a>
              </div>
              <div className="chimney-image">
                <Image className="w-full" filename="chimney/chimney-1.png" />
              </div>
            </div>
          </div>
          <div className="bg gray relative z-10 mt-0 md:pt-20 md:px-20 pt-12">
            <div className="content-block">
              <div className="md:flex reverse items-center">
                <div className="md:w-1/2">
                  <Image className="w-full" filename="chimney/about-1.jpg" />
                </div>
                <div className="md:w-1/2">
                  <div className="md:pl-20">
                    <Image filename="logo/chimney.png" alt="Shingu Chimney System 匠" />
                  </div>
                  <div className="md:mt-20 mt-6">
                    <p className="text-2xl c-yellow mb-5">SCS匠 断熱二重煙突</p>
                    <p>SCS匠 断熱二重煙突は、新宮商行が日本市場で積み重ねてきたノウハウのすべてと、日本特有のニーズを織り込み、モノづくり日本ならではの技術を駆使して開発したのが、SCS匠 断熱二重煙突です。暖炉・薪ストーブのパフォーマンスを最大限に引き出しながら、美しく快適な薪ストーブライフを楽しんでいただけます。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block">
            <h2 className="title medium">PROPERTY<span>SCS匠の特徴</span></h2>
            <div className="md:flex items-center md:mt-20 mt-6">
              <div className="md:w-1/2 md:pr-10">
                <p className="md:text-2xl text-lg c-yellow mb-5">ジョイント部の外観がすっきり！</p>
                <p>煙突とロッキングバンドを同一面に設計。視覚的にすっきりとして美しい</p>
                <p className="mt-6">SCS匠では、一般的には飛び出ているロッキング用の環状リブを、アウター外径より低く設計しました。これによりアウター管とロッキングバンドが同一面となり、外観上気になる凹凸がありません。どんな建築にも映え、室内の意匠を損なわない、美しいデザイン性を実現しました。（意匠登録・特許出願中）</p>
              </div>
              <div className="md:w-1/2 md:pl-10 md:mt-0 mt-6">
                <Image className="w-full" filename="chimney/property-1.jpg" />
              </div>
            </div>
            <div className="md:flex flex-row-reverse items-center mt-20">
              <div className="md:w-1/2 md:pl-10">
                <p className="md:text-2xl text-lg c-yellow mb-5">機能性・耐久性・美しさ</p>
                <p>主要部の溶接加工に、レーザー溶接（連続シーム溶接）を採用。レーザー溶接は歪みが少なく精密加工が可能な為、エルボや円筒部の溶接箇所は堅牢で美しい仕上がりになっています。</p>
                <p className="mt-6">表面は、サテン塗料を使った黒色粉体塗装の半光沢仕上げ。粉体塗装は一回の塗装で厚い塗膜ができ、美しい仕上がりが特徴で、耐傷性や耐熱性、耐油性にも優れています。サテン塗料を使うことで、さらにキズや指紋がつきにくく施工時の作業性も向上しました。</p>
              </div>
              <div className="md:w-1/2 md:pr-10 md:mt-0 mt-6">
                <Image className="w-full" filename="chimney/property-2.jpg" />
              </div>
            </div>
            <div className="md:flex items-center mt-20">
              <div className="md:w-1/2 md:pr-10">
                <p className="md:text-2xl text-lg c-yellow mb-5">高次元防水設計</p>
                <p>完全防水の断熱二重煙突を目指して、カプラーの形状から結合時の隙間量まで、煙突のすべてを見直した新設計です。</p>
                <p className="mt-6">これまでの一般的な煙突は、カプラー(煙突のジョイント部分)の構造上、断熱材に雨水が侵入する可能性が高いものでした。そこでカプラー形状やジョイント時の隙間量などを見直し、毛細管現象での雨水侵入を防ぐ構造にしました。また、オス・カプラーとインナー管の接合部分に1cmほど立ち上げた防水インナーリブを設け、万が一カプラーの隙間に雨水が侵入したとしても、インナー管には侵入しない構造となっています。</p>
                <p className="mt-6">継ぎ目のない一体プレス成型カプラーと密封性の高いアルゴン溶接加工、高度な技術で卓越した防水性能を実現。</p>
                <p className="mt-6">これまでの煙突のオスカプラーは、パーツ同士をカシメによって合体させていましたが、SCS匠では、高度なプレス加工技術でオスカプラーを一体成型。そこにアウター管とインナー管を密封性の高いアルゴン溶接で全溶接することで、断熱材への雨水侵入をシャットアウトしました。</p>
              </div>
              <div className="md:w-1/2 md:pl-10 md:mt-0 mt-6">
                <Image className="w-full" filename="chimney/property-3.jpg" />
              </div>
            </div>
            <div className="md:flex mt-20">
              <div className="md:w-1/2">
                <p className="text-2xl c-yellow mb-5">Made in JAPAN</p>
                <p>世界的にも厳しい検査基準をクリアした日本製ステンレス鋼板を使い、国内のISO9001認証取得工場で製造</p>
                <p className="mt-6">ステンレスは耐熱性と耐腐食性に優れた合金です。腐食リスクの高いインナー管には一般的にはSUS304が使用されていますがSCS匠では耐腐食性・耐酸性をさらに高めた薪ストーブ用煙突では最高峰素材のSUS316Lを採用。世界的にも厳しい検査基準の日本製ステンレス鋼板を使用し、国内のISO9001取得工場で製造することで、その高品質を実現しました。</p>
              </div>
              <div className="md:w-1/2 md:pl-10 md:mt-0 mt-12">
                <p className="text-2xl c-yellow mb-5">断熱効果の高い<br />スーパーウール採用</p>
                <p>環境にやさしく、しかも軽量、家屋構造に負担をかけない設計が可能。</p>
                <p className="mt-6">断熱材に断熱効率の高いスーパーウールを採用。抗張力に優れ、高温時の収縮率も低いため、均一に断熱効果を発揮します。環境にやさしく、しかも軽量。家屋構造に負担をかけない設計が可能になります。</p>
              </div>
              <div className="md:w-1/2 md:pl-10 md:mt-0 mt-12">
                <p className="text-2xl c-yellow mb-5">日本初のJIA認証取得</p>
                <p>排気筒やガス燃焼機器用の部品は、傷などがなく表面が平滑であることや、材料の適切性、耐衝撃性や断熱性などの項目を検査します。部品の使用部位や特性に応じて検査基準が定められており、学識経験者や消費者団体などで構成される委員会によって審議されます。ＳＣＳ匠は、断熱二重煙突として日本で初めてJIA認証を取得しました。（1000L筒にて申請）</p>
              </div>
            </div>
          </div>
          <div className="bg black md:py-20 md:mt-20 py-12 mt-12 relative">
            <div className="content-block">
              <h2 className="title medium text-white">LINEUP<span>SCS匠のラインナップ</span></h2>
              <ul className="flex flex-wrap -mx-4 mt-12">
                <li className="md:w-4/12 w-1/2 px-4">
                  <Image className="full-image" filename="chimney/item-1.jpg" />
                  <div className="flex text-white mt-4">
                    <p className="mr-2 text-lg">直筒</p>
                    <p className="ml-2 text-lg">1000L<br />500L<br />300L<br />150L</p>
                  </div>
                </li>
                <li className="md:w-4/12 w-1/2 px-4">
                  <Image className="full-image" filename="chimney/item-2.jpg" />
                    <div className="text-white mt-4">
                      <p className="text-lg">直筒アジャスター</p>
                      <p className="c-gray">断熱二重煙突用のアジャスター<br />
                        全て二重筒で施工する場合に便利です。</p>
                    </div>
                </li>
                <li className="md:w-4/12 w-1/2 px-4 md:mt-0 mt-12">
                  <Image className="full-image" filename="chimney/item-3.jpg" />
                  <div className="text-white mt-4">
                    <p className="text-lg">スペシャルスライド</p>
                    <p className="c-gray">シングル部材からダイレクトにSCS匠二重断熱煙突に接続できます。SCS匠からの新アイテム。</p>
                  </div>
                </li>
                <li className="md:w-4/12 w-1/2 px-4 mt-12">
                  <Image className="full-image" filename="chimney/item-4.jpg" />
                  <div className="text-white mt-4">
                    <p className="text-lg">T型90°</p>
                    <p className="c-gray">主に壁面出しの場合に使用します。下側に点検口を備えているので、煙突掃除の際に便利です。</p>
                  </div>
                </li>
                <li className="md:w-4/12 w-1/2 px-4 mt-12">
                  <Image className="full-image" filename="chimney/item-5.jpg" />
                  <div className="text-white mt-4">
                    <p className="text-lg">十字曲がり</p>
                    <p className="c-gray">主に壁面出しの際に、外壁の立ち上がり部分に使用します。上部方向だけでなく、室内側への点検・掃除口を備えています。</p>
                  </div>
                </li>
                <li className="md:w-4/12 w-1/2 px-4 mt-12">
                  <Image className="full-image" filename="chimney/item-6.jpg" />
                  <div className="text-white mt-4">
                    <p className="text-lg">直筒300Lダンパー付</p>
                    <p className="c-gray">煙突のドラフトが強い場合やストーブ本体の空気調整だけでは空気が入りすぎてしまう場合に使用します。</p>
                  </div>
                </li>
                <li className="md:w-4/12 w-1/2 px-4 mt-12">
                  <Image className="full-image" filename="chimney/item-7.jpg" />
                  <div className="text-white mt-4">
                    <p className="text-lg">ロッキングバンド</p>
                    <p className="c-gray">煙突の接続部に使用します。ツイストロック部に使用し、接続を強化します。ビスを使用する場合は、ツイストロック部に打ち、ロッキングバンドで隠すことができるので、意匠性を損なわず、安全に施工できます。</p>
                  </div>
                </li>
                <li className="md:w-4/12 w-1/2 px-4 mt-12">
                  <Image className="full-image" filename="chimney/item-8.jpg" />
                  <div className="text-white mt-4">
                    <p className="text-lg">エルボ（30°,45°,90°）</p>
                    <p className="c-gray">煙突は、真っすぐ上げることが理想的ですが、現実的には曲げることが必要になる場合があり、その場合に使用します。</p>
                  </div>
                </li>
                <li className="md:w-4/12 w-1/2 px-4 mt-12">
                  <Image className="full-image" filename="chimney/item-9.jpg" />
                  <div className="text-white mt-4">
                    <p className="text-lg">ウォールサポートベース<br />
                      ウォールサポートベースSTD</p>
                    <p className="c-gray">主に壁面出しで使用します。垂直部分の荷重を支えることが出来ます。</p>
                  </div>
                </li>
                <li className="md:w-4/12 w-1/2 px-4 mt-12">
                  <Image className="full-image" filename="chimney/item-10.jpg" />
                  <div className="text-white mt-4">
                    <p className="text-lg">スライドアダプターS+W</p>
                    <p className="c-gray">シングルからSCS匠断熱二重煙突に接続する際に使用します。<br />
                      この部材で、長さを調整することもできます。</p>
                  </div>
                </li>
                <li className="md:w-4/12 w-1/2 px-4 mt-12">
                  <Image className="full-image" filename="chimney/item-11.jpg" />
                  <div className="text-white mt-4">
                    <p className="text-lg">変換アダプター</p>
                    <p className="c-gray">従来のSRCや規格の異なるツイストロックとSCS匠を接続する為の変換アダプターです。</p>
                  </div>
                </li>
              </ul>
              <div className="lineup-bg">
                <Image className="full-image" filename="chimney/lineup-bg.jpg" />
              </div>
            </div>
          </div>
          <div className="content-block">
            <div className="md:flex">
              <div className="md:w-1/2 md:pr-20">
                <p className="text-2xl c-yellow mb-5">煙突の重要性</p>
                <p>薪ストーブの性能を引き出すためには薪ストーブから効率よく煙を排出し、外気と入れ替えて上げることが重要です。薪ストーブから排出された暖かい煙は外気の冷たい空気より軽いため煙突を伝って上へと昇っていきます。<br />
                  この上へと昇っていく力を ドラフト と呼びます。<br />
                  ドラフトは煙突の内外の温度差が大きいほど強くなるため煙突内部の温度を高温に保つことが重要です。また適切なドラフトを得られない煙突は煤(スス)やタールが付着しやすく、煙道火災の原因にもなってしまいます。</p>
              </div>
              <div className="md:w-2/12 w-6/12 md:mx-0 mx-auto md:mt-0 mt-6">
                <Image className="full-image" filename="chimney/draft.jpg" />
              </div>
            </div>
          </div>
          <div className="content-block">
            <Link className="btn full white" to="/accessory/">
              その他のアクセサリはこちらから
            </Link>
            <a className="btn pdf full mt-5" href="/update/pdf/chimney-pamphlet.pdf" target="_blank">
              SCS匠 パンフレット ダウンロード
            </a>
            <a className="btn pdf full mt-5" href="/update/pdf/chimney-price.pdf" target="_blank">
              SCS匠 煙道部材価格表 ダウンロード
            </a>
          </div>
        </div>
      </section>
      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>煙突</li>
        </ul>
      </section>
    </Layout>
);

export default ChimneyPage;
